@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.prenavcontainer{
    height: 20px;
    width: 100vw;
    background-color: rgb(47, 0, 33);
    color: white;
    font-size: 12px;
    letter-spacing: 2px;
    font-family: 'Rubik', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.navcontainer{
    width: 100vw;
    height: 65px;
    display: flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
}

.navinnercontainer{
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}

.navlogocontainer span{
    font-size: 40px;
    /* letter-spacing:10px; */
    font-family: 'Great Vibes', cursive;
    cursor: pointer;
}

.navicons{
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
}

.navoptions{
    width: 600px;
    display: flex;
    justify-content: space-evenly;
}

.navoptions span{
    padding: 10px;
}

.navoptions span:hover{
    position: relative;
    bottom:  5px;
    cursor: pointer;
}


@media screen and (max-width: 450px) {
    .navoptions span{
        padding: 2px;
    }
    .navlogocontainer span{
        font-size: 20px;
        letter-spacing: 5px;
    }
    

    .navicons{
        font-size: 15px;
        width: 100px;
    }

}

@media screen and (max-width:638px) {
    .navoptions{
        display: none;
    }

    .Typewriter__wrapper{
        font-size: 10px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');

.weddinglanding
{
    width: 100%;
    height: 650px;
    background-color: black;
    padding-left: 40px;
    padding-right: 40px;
}

.weddinginner{
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("./pexels-min-an-758898-min.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.maskerforwedding{
    height: 100%;
    width: 90%;
    /* background-color: black; */
    /* opacity: 0.5; */
    font-family: 'Dancing Script', cursive;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    /* padding: 100px; */
}

.maskerforwedding span{
    font-size: 60px;
    margin-bottom: 30px;
}

.maskerforwedding p{
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-bottom: 30px;
}

.buywedding{
    border: none;
    background-color: rgb(47, 0, 33);
    padding: 17px 35px;
    color: white;
    font-family: 'Roboto', sans-serif;
    border-radius: 10px;

}
.buywedding:hover{
    background-color: rgb(86, 14, 64);
}

.featureswed{
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-bottom: 70px ;
}

/* .featurewedhead{
    font-size: 40px;
    margin-top: 30px;
} */



.featurediv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
   
    
}

.featuredimgdiv img{
    height: 500px;
    width: 300px;
}

.featuedcontentdiv{
    text-align: right;
    padding: 30px;
    margin-left: 70px;
}


.featurediv2{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    
}

.featuredimgdiv2 img{
    height: auto;
    width: 300px;
}

.featuedcontentdiv2{
    text-align: left;
    padding: 50px;
    margin-right: 70px;
}

.featuedcontentdiv, .featuedcontentdiv2{
    display: flex;
    flex-direction: column;
}

.featuredcontectheading{
    font-family: 'Dancing Script', cursive;
    font-size: 45px;
    margin-bottom: 30px;

}

.lwidget{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(251, 250, 250);
    width: 100%;
    /* height: 100%
     */
    height: 350px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.2s;
}

.lwidget img{
    width: 100%;
    height: 100%;
}


  
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .lwidget:hover img {
    opacity: 0.3;
  }
  
  .lwidget:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: rgb(47, 0, 33);
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }





  
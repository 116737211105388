button,i {
    cursor: pointer;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

/* ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: black;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #FFFF;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  } */

.limginfo{
    height: 210px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: 'Rubik', sans-serif;
    position: absolute;
    top: 50%;
    left: 18%;
    transform: translateY(-50%);
    width: 400px;


}


.limginfoL{
    height: 210px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: 'Rubik', sans-serif;
    position: absolute;
    top: 50%;
    right: 22%;
    transform: translateY(-50%);
    width: 400px;


}

.limginfo button, .limginfoL button{
    border: none;
    border-radius: 2px;
    margin-top: 5px;
    width: 150px;
    padding: 15px;
    background-color: rgb(47, 0, 33);
    color: white;
}

.limginfo2{
    font-size: 36px;
    text-align: left;
    /* font-weight: 500; */
}

img{
    object-fit: cover;
    object-position: top;
}

.ourbs
{
   
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

}

.bsheader
{
    font-size: 36px;
    font-weight: 400;
}

.bsgrid{
    margin-top: 60px;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 40px;
    height: 100%;


}

.carousel-item{
  height: 700px;
  padding-right: 40px;
  padding-left: 40px;
  background-color: black;
}


@media only screen and (max-width: 1067px) {
    .carousel-item{
      height: 500px;
    }

    .limginfo1
    {
      font-size: 16px;
    }

    .limginfo2{
      font-size: 25px;
    }

    .limginfo button, .limginfoL button{
      border: none;
      border-radius: 2px;
      width: 110px;
      padding: 10px;
      background-color: rgb(47, 0, 33);
      color: white;
  }



.limginfoL{
  height: 140px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Rubik', sans-serif;
  position: absolute;
  top: 40%;
  right: 10%;
  transform: translateY(-50%);
  width: 300px;


}

.limginfo{
  height: 140px;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Rubik', sans-serif;
  position: absolute;
  top: 50%;
  left: 18%;
  transform: translateY(-50%);
  width: 300px;


}
  }


  @media only screen and (max-width:563px)
  {
    .bsgrid{
      
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      
  
  
  }
  }
  @media screen and (max-width:638px) {

    .limginfoL{
      height: 140px;
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-family: 'Rubik', sans-serif;
      position: absolute;
      top: 40%;
      right: 5%;
      transform: translateY(-50%);
      width: 200px;
    
    
    }
    
    .limginfo{
      height: 140px;
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-family: 'Rubik', sans-serif;
      position: absolute;
      top: 50%;
      left: 15%;
      transform: translateY(-50%);
      width: 200px;
    
    
    } 

    .carousel-item{
      padding-left: 0px;
      padding-right: 0px;
      height: 400px;
    }

    .limginfo1
    {
      font-size: 10px;
    }

    .limginfo2{
      font-size: 16px;
    }

    .limginfo button, .limginfoL button{
      border: none;
      border-radius: 2px;
      width: 90px;
      padding: 8px;
      background-color: rgb(47, 0, 33);
      color: white;
  }
  }

  @media only screen and  (max-width:563px) {
    .bsgrid{
      grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    }
  }

  
/* @media screen and (max-width: 600px) {
  img{
    object-fit: contain;
    object-position: center;
    height: 300px;
    width: 300px;
  }

  .carousel-item{
    height: 300px;
    padding-right: 40px;
    padding-left: 40px;
    background-color: black;
  }
 
  .limginfo1{
    font-size: 15px;
    
  }
  .limginfo2{
    font-size: 15px;
  }

  .limginfo .limginfo1, .limginfo .limginfo2{
    width: 150px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  .limginfoL > .limginfo1{
    width: 100px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    margin-left: 150px;
    

  }
  .limginfoL > .limginfo2{
    width: 100px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    margin-left: 200px;
  }

  .limginfoL > .limginfo3{
    width: 100px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    margin-left: 180px
  }

  .limginfoL > button{
    width: 50px;
    margin-top: 5px;
    font-size: 15px;
    padding: 5px;
  }
  
} */
